/*font-family: 'EQUALI', sans-serif;*/

@font-face {
    font-family: 'EQUALI';
    src: url('EQUALI.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
.size-selector{
    display: flex;
    width: 100%;
    gap: 1rem;
}

.product-page-special-image{
    max-width: 550px;
    padding: 1rem;
}

.price{
    background-color: #C3D833 ;
    padding: .3rem .8rem;
    border-radius: 20px;
}

.pill-button{
    border-radius: 20px;
    border: 2px #241F5F dotted;
    min-width: 80px;
}

.pill-button .selected{
    box-shadow:  20px 20px 60px #d7d6d6,
             -20px -20px 60px #ffffff;
}

.close-menu {
    display: none
}

.navbar-nav {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.nav-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.nav-link-wrapper{
    display: flex;
    flex-wrap: wrap;
}




.eql-font {
    font-family: 'EQUALI', 'Roboto';
    font-display: optional
}

.border-t {
    border-top: #150764 2px solid;
}

.mt-large {
    margin-top: 7rem;
}

.product-card-mt-large {
    margin-top: 7em;
}

.primary-btn-container {
    display: flex;
    max-height: 40px;
    align-items: center;
}


body,
html {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    /* Prevent horizontal scrolling on the whole page */
}

.nav-font {
    font-size: 1.5rem;
    color: #150764 !important;
}

.t-accent-1 {
    font-size: 1.75rem !important;
}


.affliate-graphic-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #4188FF;
}


.nav-container {
    position: sticky;
    top: 0;
    background-color: transparent;
    z-index: 999;
    transition: all 0.5s ease;
    overflow: hidden;
}

.logo-container {
    border-bottom: 1px solid black;
    border-top: 1px solid black;
}

.affiliate-btn {
    background-color: #C3D833;
    color: #241F5F;
    padding: 1rem;
    border-radius: 8px;
    margin-top: 1rem
}

.nav-container.scrolled {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
}

.container.scrolled {
    padding: 10px;
    position: fixed;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    transition: all 0.5s ease;
    background-color: white;
    min-width: 100%;
    max-width: 100%;
    border-bottom: #150764 2px solid;
    z-index: 50;
    width: 100%;

}

.man {
    width: auto;
    height: 100%;
}

.affiliate-container {
    background-color: #4188ff;
    height: 100%;
    padding: 4rem 2rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}


.affiliate-left-wrapper {
    width: 600px;
    text-align: left;
    padding: 1rem;
    display: flex;
    flex-direction: column;

}

.affiliate-right-wrapper {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.affiliate-graphic {
    max-width: 750px;
    display: block;
    margin-left: auto;
    margin-right: auto;
}


.affiliate-left-wrapper p {
    font-size: 1.1rem;
}

.affiliate-left-wrapper img {
    margin-top: 2rem;
    width: 300px;
    height: auto
}

.nav-link {
    color: white
}


.subli-snap-logo {
    transition: all 0.3s ease;
    margin: auto;
    width: 1250px;
    padding: 10px;
}

.container.scrolled .subli-snap-logo {
    width: 200px;
}

.bg-color-nav {
    background-color: #4188FF;
    z-index: 10;
}

.subli-snap-container {
    display: flex;
    min-width: 100%;
    max-width: 100%;
    border-bottom: solid 2px#150764;
    padding: 10px;
    border-top: solid 2px #150764;
    overflow-x: hidden;

}

.pseudo-link-home {
    margin-left: auto;
    margin-right: 5%;
    width: 190px;
    height: 50px;
}

.mobile-home-link {
    display: none;
}

.pseudo-link-home:hover {
    text-decoration: none;
}

.order-now-btn {

    background-color: #c3d833;
    color: #150764;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    margin-left: 1rem
}

.order-now-btn:hover {
    background-color: #150764;
    color: #c3d833
}


.show {
    background-color: #4188FF;
}

.marquee-container {
    color: white;
    max-width: 100%;
    font-size: 1.7rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 3.5rem;
    overflow: hidden;
}

.c-craved-heading {
    text-align: center;
    margin-bottom: 6.5em;
    margin-left: auto;
    margin-right: auto;
}

.c-icon-headline.home-eyes {
    grid-column-gap: .25em;
    grid-row-gap: .25em;
    justify-content: center;
    align-items: center;
}

.t-accent-1.is-blue {
    color: #4188ff;
}

.t-accent-1 {
    text-transform: uppercase;
    margin-top: 0;
    margin-bottom: 0;
    font-family: Neue Plak Extended, sans-serif;
    font-size: 24px;
    font-weight: 900;
    line-height: 1;
}

.c-eyes.home {
    transform: translate(.05em, -.1em) rotate(-15deg);
}


.c-eyes {
    justify-content: center;
    align-items: center;
    display: flex;
}



.w-embed:before,
.w-embed:after {
    content: " ";
    grid-area: 1 / 1 / 2 / 2;
    display: table;
}

.w-embed:after {
    clear: both;
}

.home-eyes {
    display: flex;
    margin-top: 5rem;
}

.t-display-1 {
    margin-top: 0;
    margin-bottom: 0;
    font-size: max(107px, min(7.43vw, 127px));
    font-weight: 900;
    color: #150764;
    line-height: max(107px, min(7.43vw, 127px));
}

.card-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 2rem;
    flex-wrap: wrap;


}

.card {
    display: flex;
    flex-direction: column;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    border: solid 2px #150764;
}

.card-img {
    overflow-x: hidden;
}

.youtube-container {
    background-color: #C3D833;
    padding: 1rem;
    border-radius: 20px;
}


.blue-bg {
    background-color: #4188FF;
    border-bottom: #150764 2px solid;
    border-radius: 0;
}

.orange-bg {
    background-color: #FEA100;
    border-bottom: #150764 2px solid;
    border-radius: 0;
}

.red-bg {
    background-color: #E7271C;
    border-bottom: #150764 2px solid;
    border-radius: 0;
}

@keyframes marquee-animation {
    0% {
        transform: translateX(100%);
    }

    100% {
        transform: translateX(-100%);
    }
}

@media (max-width: 1270px) {
    .subli-snap-logo {
        width: 1000px
    }
}

@media (max-width: 995px) {
    .show {
        padding: 10px;
        border-radius: 20px;
        border: #150764 2px solid;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1)
    }
    .primary-btn-container {
        margin-top: 1rem
    }

        .nav-link-wrapper {
            flex-direction: column;
        }


    .close-menu {
        font-size: 24px;
        width: 40px;
        font-weight: bold;
        cursor: pointer;
        color: #000;
        margin-left: 90%;
        font-family: sans-serif;
        border: 2px solid #12084d;
        border-radius: 20px;
        display: block;
    }


    .navbar {
        padding: .5rem 2.5rem;
    }

    .pseudo-link-home {
        display: none;
    }

    .mobile-home-link {
        display: block;
        margin-bottom: 1rem;
    }

    .navbar-collapse {
        transition: background-color 0.1s ease-out;
        background-color: rgba(255, 255, 255, 0.95);
        opacity: 0;

    }

    .navbar-collapse.show {
        opacity: 1;
        height: fit-content;
        padding: 1rem 2rem;
        width: fit-content;
        margin-left: auto;
        margin-right: auto;
    }

    .product-page-container {
        flex-direction: column;
        padding: 2rem;
    }

    .affiliate-left-wrapper img {
        display: none;
    }

    .subli-snap-logo {
        width: 100%
    }

    .parallax-section,
    .parallax-container,
    .product-image-parallax {
        
    }


}

.about-container {
    width: 100%;
    height: 100%;
    background-color: #c3d833;
    display: flex;
    flex-wrap: wrap;
    padding: 3rem 5rem;
    border: solid 2px #150764
}

.about-text-wrapper {
    line-height: .9;
    color: #150764;
    max-width: 50%;
    text-align: left;
    padding: 1rem;
}

.about-img-wrapper {
    max-width: 50%;
    position: relative;
    margin: auto;
}

.about-text {
    font-size: 4rem;
    max-width: 500px;
    width: 100%;
}

.about-img {
    animation-name: animate-bounce;
    animation: animate-bounce 20s ease 0s infinite normal forwards;
    max-width: 400px;
    padding: 2rem;
}

.about-img-2 {
    animation-name: animate-bounce;
    animation-duration: 20s;
    animation-timing-function: ease-in-out;
    /* Changed to ease-in-out for smoother general easing */
    animation-iteration-count: infinite;
    animation-direction: normal;
    animation-fill-mode: forwards;
    max-width: 250px;
    padding: 2rem;
    position: absolute;
    top: 0;
    left: 200px;
}

.about-text-wrapper span {
    font-size: 1.2rem;
    max-width: 600px;
    line-height: 1.5;
}

.oval-container {
    display: flex;
    gap: 1rem;
}

.oval {
    background-color: white;
    width: 200px;
    height: 50px;
    border-radius: 20px;
    align-items: center;
    justify-content: center;
    display: flex;
    text-align: center;
    margin-top: 1rem;
}


@keyframes animate-bounce {

    0%,
    100% {
        transform: translateY(0px);
        /* Start and end at the same position */
        opacity: 1;
    }

    15%,
    85% {
        transform: translateY(45px);
        /* Higher peak for smoother bounce */
    }

    30%,
    70% {
        transform: translateY(20px);
    }

    50% {
        transform: translateY(40px);
    }

}




@media (max-width: 928px) {
    .about-container {
        justify-content: center;
        align-items: center;
        overflow-y: hidden;

    }

    .about-text-wrapper {
        max-width: 100%;
    }

    .about-text {
        font-size: 2.5rem;


    }


    .about-img-wrapper {
        max-width: 100%;


    }

    .about-img {
        max-width: 300px;
        margin-bottom: 1rem;
    }

    .about-img-2 {
        max-width: 200px;
        left: 150px;
    }


}



/* Parallax*/
.parallax-section {
    position: relative;
    height: 850px;
    width: 100%;
    overflow: hidden;
}

.svg-background {
    position: absolute;
    top: 10px;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
}

.spray-bottle-care h2 {
    margin-top: 1rem;

}

.instructions-section {
    margin-top: 1rem;
}

.instructions-section h2 {
    font-family: Equali;
    color: #0d024b;
}

.heat-settings p {
    border: 2px solid #0d024b
}

.color-background {
    position: absolute;
    top: 20px;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -2;
    background-color: #150764;
}

.parallax-background {
    position: absolute;
    top: 10px;
    left: 0;
    width: 100%;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: -1;
}

.nav-section {
    width: 100vw;
    padding: 0 4rem 0 2rem;
}

.foreground-image {
    position: absolute;
    left: 0;
    width: 100%;
    height: auto;
    padding: 0;
    margin: 0;
    z-index: 0;
    pointer-events: none;
}


.foreground-image-mobile {
    display: none;
}


.utility-flex {
    display: flex;
    flex-direction: column;
}

.buy-product-btn {
    margin-top: 1rem;
    text-align: center;
    background-color: #c3d833;
    color: #120655;
    padding: .75rem 0;
}

.buy-product-btn:hover {
    color: #c3d833;
    background-color: #120655;
}

.product-page-title {
    color: #120655
}

@media (max-width: 730px) {
    .parallax-section {
        max-height: 500px;
    }

    .about-container {
        padding: 1rem 1.5rem;
    }

    .foreground-image-mobile {
        position: absolute;
        left: 0;
        width: 100%;
        z-index: 0;
        pointer-events: none;
        display: flex;
    }

    .foreground-image {
        display: none;
    }

    .product-page-container {
        padding: .5rem;
    }

    .product-page-title {
        font-size: 1.75rem;

    }

    .utility-flex {
        flex-direction: column-reverse;
    }

    .buy-product-btn {
        margin-bottom: 1rem
    }

    .affiliate-container {
        padding: 1rem;
        box-sizing: border-box;
    }

    .affiliate-right-wrapper {
        box-sizing: border-box;
        width: 100%;
        align-items: center;
    }

    .affiliate-graphic {
        max-width: 470px;

    }

    .ten-percent {
        max-width: 300px;
    }




}





.svg-background svg #water #waveShape {
    animation: waveAction01 2s linear infinite;

}


#water #waveShape {
    animation-name: waveAction01;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    animation-duration: 2s;
}

@keyframes waveAction01 {
    0% {
        transform: translate(300px, -1830px);
    }

    100% {
        transform: translate(102px, -1830px);
    }
}

.parallax-container {
    position: relative;
    width: 100%;
    height: 100%;
    bottom: -200px;
    display: flex;
    justify-content: center;
}

.product-image-parallax {
    position: absolute;
    z-index: 3;
    bottom: 250px;
    max-height: 600px;
    filter: drop-shadow(4px 4px 10px rgba(0, 0, 0, 0.25));

}

.call-to-action-top {
    height: 250px;
    border-top: solid 2px #150764;
    background-color: #150764;
}

.call-to-action-container {
    background-color: #3C82FC;
    height: 200px;
    border-top: solid 2px #150764;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
}

.call-to-action {
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 1rem;
}

.call-to-action-left {
    border: solid 2px #150764;
    border-top: none;


}

.call-to-action-right {
    border: solid 2px #150764;
    border-top: none;
    border-left: none;
}



.open-product-page {
    text-decoration: none;
    padding: 1rem 2rem;
    width: 500px;
    height: 100px;
    color: white;
    font-size: 3.5rem;
    line-height: 3.8rem;
    border-radius: 50px;
    background: #150764;
    box-shadow: 20px 20px 20px #3771d6,
        -20px -20px 20px #0d56d3
}

.open-product-page:hover {
    text-decoration: none;
    text-shadow:
        1px 1px 2px black,
        -1px -1px 2px black,
        -1px 1px 2px black,
        1px -1px 2px black;
    color: #C3D833;
    border-radius: 50px;
    background: linear-gradient(145deg, #2257b4, #3771d6);
    box-shadow: 20px 20px 60px #0d56d3,
        -20px -20px 60px #4188FF;
}

.call-to-action-right:hover {
    text-decoration: none;
    background-color: #150764;
}

.call-to-action-left:hover {
    text-decoration: none;
    background-color: #150764;
}

.card-txt {
    font-size: 1.5rem;
    color: #150764;
    border-bottom: solid 2px #150764;
}

.bottom-txt-wrapper {
    display: flex;
}

.bottom-txt-wrapper span {
    flex-basis: 30%;
    font-size: 2rem;
    background-color: #150764;
    color: white;
    text-align: center;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom-left-radius: 16px;
}

.bottom-txt-wrapper p {
    margin-top: auto;
    margin-bottom: auto;
}

.card-container .card p {
    padding: 0 1rem;
    text-wrap: wrap;
    max-width: 285px;
    font-weight: 500;
    margin-left: auto;
    margin-right: auto;
    text-align: left;
}

.features-container {
    max-width: 100%;
    margin: auto;
    background-color: #c3d833;
}

.features-title {
    text-align: center;
    font-size: 3rem;
    color: #12084d;
    max-width: 100%;
    border: solid 2px #150764;
    padding: 1rem;
    margin: 0;
    overflow: hidden;
}

.features-grid {
    background-color: #150764;
}

.features-container .features-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
    border-bottom: 2px solid #150764;
    padding: 2rem;
}

.features-container .features-grid>div {
    background-color: #c3d833;
    padding: 20px;
    text-align: left;
    border: 2px solid #150764;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    font-size: 1.2rem;
    color: #150764
}


div .about-text-wrapper h2 {
    font-size: 1.5rem
}

.bg-color-footer {
    background-color: #120655;
    color: white;
    width: 100%;
    height: 10rem;
    bottom: 0;
    position: relative;
}

.footer-nav {
    display: flex;
    text-decoration: none;
    list-style-type: none;
    justify-content: center;
    gap: 1rem;
    padding: 1rem;
}


.twitter-container img {
    max-height: 300px;
    border: #150764 2px solid;
}

.insta-form {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    background-color: #3C82FC;
    color: #150764;
    align-items: center;
    padding: 2rem;
}

.insta-form-left {
    background-color: white;
    border-radius: 20px;
    border: 2px solid #120655
}

.insta-tag {
    color: #120655 !important;
}

.insta-form-left,
.insta-form-right {
    flex: 1;
    padding: 2rem;
    font-size: 1.2rem;
}

.insta-mock {
    height: 400px;
    transform: rotate(8deg);
}

.insta-txt-left {
    margin: 20px;
    text-align: left;

}

.insta-txt-left h2 {
    font-size: 3.5rem;
    color: #150764
}

.insta-action-right {
    margin: 20px;
}










.product-page-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 2rem;
    margin-top: 5rem;
}

.right-text-wrapper {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-top: 1rem;


}

.right-text-wrapper p {
    margin: 0;
}

.icon-check {
    max-width: 50px;
}

.right-side-pp {
    display: flex;
    flex-direction: column;
    padding: 4rem;
    gap: 1rem;
    text-align: left;
}

.product-page-main-image {
    max-width: 700px;

}

.features-grid span {
    font-size: smaller;
}


.features-margin-zero {
    margin-top: 0;
}

.right-side-pp {
    display: flex;
}

.product-page-main-image {
    max-width: 500px;
}


.faq-container {
    text-align: left;
    margin: 2rem;

}

.card-social {
    background-color: #4188FF;
    border: solid 2px #120655;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    max-width: 32%;
    min-width: 300px;
    padding: 1rem
}

.social-bottom-wrapper {
    display: flex;
    margin-top: 4rem;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    border: 2px solid #120655;
    border-top-right-radius: 20px;
    flex-wrap: wrap;
}

.social-bottom-left {
    max-width: 50%;
    margin: auto;
    padding: 2rem;

}

.social-bottom-left p {
    font-size: 1.2em;

}

.social-bottom-right {
    max-width: 50%;
    background-color: #12084d;
    padding-left: 3.5rem;
    border-top-right-radius: 18px;
    border-left: 2px solid #120655
}

.social-bottom-right img {
    max-width: 500px;

}

.social-card-wrapper {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
}

.social-header {
    background-color: #12084d;
    color: white;
    padding: 1rem;
}

.about-instagram {
    margin: 2rem auto;
    padding: 2rem;
    line-height: 1.6;
    text-align: left;
}

.top-header-social {
    text-align: center;
    max-width: 800px;
    margin: 0 auto 4rem auto;
}

.top-header-social p {
    font-size: 1.2em;
}

.about-instagram h2,
h3 {
    color: #12084d;
    margin-bottom: 10px;
}



.about-instagram p {
    margin-bottom: 15px;
}

.about-instagram ul {
    list-style-type: none;
    padding-left: 20px;
}

.about-instagram li {
    margin-bottom: 10px;

}

.about-instagram strong {
    color: #000;
}

.mr-2rem {
    margin-right: 2rem
}


.prep-step {
    display: flex;
    justify-content: space-between;
    padding: 1rem 4rem;
    flex-wrap: wrap-reverse;

}

.prep-step ul {
    text-align: left;
}

.youtube-video {
    border: 2px solid #C3D833;
    padding: 1rem;
    border-radius: 20px;
    max-width: 390px;
    max-height: 250px;
    margin: auto;
}


.materials {
    border: 2px solid #C3D833;
    padding: 1rem;
    border-radius: 20px;
    margin: 1rem;
    max-width: 400px;
}

.pre-press {
    text-align: left;
    max-width: 700px;
    padding: 1rem;
}



.instructions-container ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    flex-direction: column;
    width: 100%;
    padding-left: 0;
}

.how-to-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin: 2rem 1rem;
    color: #241F5F;
}

.instructions-container {
    color: #241F5F;
}

.instructions-container li {
    border: #0d56d3 2px dotted;
    list-style: none;
    border-radius: 20px;
    padding: 1rem;
    width: 100%;
    text-align: left;
    color: rgb(10, 10, 10)
}

.how-to-container h1 {
    font-family: Equali;
}

.how-to-container h2 {
    margin-bottom: 1rem
}

.how-to-container span {
    border: 1px solid black;
}

.modal-choices {
    display: flex;
    gap: 1rem;
    width: 100%;
    align-items: start;
    margin-left: 20%;
}


/******** DTF SECTION ***************************************/
.dtf-section-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem;
}

.flex-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    max-width: 900px;
    margin-bottom: 20px;
}

.checklist-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.check-mark {
    width: 20px;
    height: 20px;
    margin-right: 10px;
}

.checklist {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-right: 20px;
    background-color: #F3F7FD;
    padding: 2rem;
    box-shadow: 5px 0px 10px rgba(0, 0, 0, 0.15);
}

.dropzone {
    border: 2px dashed #ccc;
    padding: 20px;
    text-align: center;
    margin-bottom: 20px;
    max-width: 400px;
    min-height: 300px;
    width: 100%;
    display: flex;
    align-items: center;
}

.uploaded-image {
    max-width: 100%;
    height: auto;
}

.artwork-dimensions {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 400px;
}

.artwork-dimensions__form-number-row,
.artwork-dimensions__price-row {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
}

.artwork-dimensions__form-number-group {
    margin-right: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.artwork-dimensions__number-label {
    font-size: 14px;
    margin-bottom: 5px;
}

.artwork-dimensions__number-control {
    display: flex;
    align-items: center;
}

.artwork-dimensions__number-field {
    width: 80px;
    padding: 5px;
    font-size: 14px;
    text-align: center;
}

.artwork-dimensions__multiplier,
.artwork-dimensions__equal-sign {
    font-size: 24px;
    font-family: sans-serif;
    margin-top: 1.5rem;
    margin-right: .25rem;
}

.artwork-dimensions__form-number-group--price .artwork-dimensions__static-input {
    font-size: 14px;
    padding: 5px;
    border: 1px solid #ddd;
    background-color: #f7f7f7;
}

.artwork-dimensions__free-note {
    font-size: 12px;
    color: #777;
    margin-top: 10px;
}

.submit-button {
    background-color: #4CAF50;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 20px;
}

.submit-button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1000;
}

.modal-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    width: 90%;
    max-width: 500px;
    max-height: 90%;
    overflow-y: auto;
    text-align: center;
    position: relative;
}

.close-modal {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
}

.progress-indicator {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.circle {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #ccc;
    margin: 0 5px;
}

.circle.active {
    background-color: #4CAF50;
}

.modal-image {
    width: 150px;  
    height: auto;
    margin-bottom: 20px;
}

.thank-you-message {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.thank-you-message h2 {
    margin-bottom: 10px;
}

.thank-you-message p {
    margin-bottom: 20px;
}

.thank-you-message button {
    background-color: #4CAF50;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.user-wrapper{
    display: flex;
    gap: 6px;
}

.input-form{
    border: none;
    box-sizing: border-box;
}

.sudo-border{
    border: solid 1px #b4b4b4;
    border-radius: 20px;
    padding: .5rem;
}

.note-form{
    border: solid 1px #b4b4b4;
    border-radius: 10px;
    width: 100%;
    margin-top:1rem;
    text-indent: 6px;
}


/***********************************************************Start of Media Queries************/


/* Media Queries for larger screens */
@media (min-width: 1300px) {
    .features-container .features-grid {
        grid-template-columns: repeat(4, 1fr);
        /* 4 columns for wider screens */
    }

    .product-page-main-image {
        max-width: 600px;
    }


}


@media (max-width: 1299px) {
    .features-container .features-grid {
        grid-template-columns: repeat(2, 1fr);
    }

    .open-product-page {
        font-size: 3rem;
        line-height: 4rem;
    }

    .call-to-action-container {
        height: 150px
    }

    .pre-press {

        max-width: 520px;

    }

}

@media (max-width: 1024px) {
    .hero-image {
        overflow: hidden;
    }


    .product-page-container {
        flex-direction: column;
    }

    .social-bottom-wrapper {
        flex-direction: column;
        border-top-left-radius: 20px;
    }

    .social-header {
        font-size: 1.5rem;
    }

    .social-bottom-left {
        max-width: 100%;
        text-align: center;
    }

    .social-bottom-left p {
        max-width: 600px;
    }

    .social-bottom-right {
        max-width: 100%;
        border-top-right-radius: 0;
    }

    .social-bottom-right img {
        max-width: 100%;
    }

    .pre-press {
        max-width: 400px;
    }

}

@media (max-width: 1000px) {
    .social-card-wrapper {
        flex-direction: column;
        padding: 0 4rem;
    }

    .card-social {
        max-width: 100%
    }

    .navbar-nav {
        margin-bottom: 1rem;
      

    }


}

@media (max-width: 820px) {
    div .hero-text h1 {
        font-size: 2rem;
    }


    .about-text-wrapper {
        text-align: center;

    }

    .about-text {
        max-width: 100%;
    }

    .instructions-container {
        width: 100%;

    }

    .instructions-container>div {
        Width: 48%
    }

    .pre-press {
        max-width: 100%;
    }

    .materials {
        width: 100%;
    }
}


@media (max-width: 770px) {

    .about-container {
        margin-bottom: 2rem
    }

    #about-section.mt-large {
        margin-top: 2rem;
    }

    .c-craved-heading {
        margin-bottom: 2rem;
    }


    .features-container .features-grid {
        grid-template-columns: repeat(1, 1fr);
    }

    .social-card-wrapper {
        padding: 0 2rem;
    }

    .call-to-action-container {
        padding: 0 1rem;
        background-color: #150764;
    }

    .open-product-page {
        box-shadow: 20px 20px 20px #0d024b,
            -20px -20px 20px #12084d
    }

    .open-product-page {
        font-size: 1rem;
        line-height: 3.1rem;
        max-height: 50px;
        max-width: 46vw;
        text-align: center;
        padding: 0 .5rem;
    }


    .call-to-action-container {
        height: 100px;
        padding-bottom: 3rem
    }

    .features-title {
        font-size: 5rem;
        padding-top: .5rem;
    }

    .product-image-parallax {
        max-height: 400px;
  
    }

    .product-page-main-image {
        max-width: 600px;

    }
    .product-page-special-image{
        max-width: 400px;
        padding: 1rem;
    }

    .right-side-pp {
        padding: .75rem
    }

    .features-title {
        font-size: 2rem
    }

    div .about-text-wrapper h2 {
        font-size: 1.2rem;
        padding-top: .5rem;
    }

    .insta-form {
        display: block;
        min-width: 100%;
        padding: 0;

    }

    .insta-form-left {
        padding: 1rem 0;
        margin: 0;
        border-radius: 0;
    }

    .insta-form-left h2 {
        font-size: 2rem;
    }

    .insta-form-right {
        display: none
    }

    .call-to-action-top {
        height: 200px
    }

    .social-bottom-right img {
        padding-right: 1rem;
    }

}

@media (max-width: 630px) {
    .product-card-mt-large {
        margin-top: 0;

    }



    .card-contianer {
        gap: 0
    }

    .card {
        width: 100%;
        margin: 2rem;

    }

    .nav-container.scrolled {
        padding-left: 1rem !important
    }

}

@media (max-width: 500px) {
    .social-card-wrapper {
        padding: 0 .5rem;
    }

    .social-bottom-left h3 {
        font-size: 2rem;
    }

    .social-bottom-right img {
        padding-right: 2rem;
    }

    .affiliate-graphic {
        max-width: 300px;

    }


    .instructions-container>div {
        Width: 100%
    }

    .prep-step {
        width: 100%;
        padding: .75rem
    }

    .materials {
        margin-right: auto;
        margin-left: auto;
    }

}



.margin-bottom-20 {
    margin-bottom: 2rem;
}


.needsclick.kl-private-reset-css-Xuajs1 a svg {
    display: none;
}

.disqus-container {
    max-width: 700px;
    margin-right: auto;
    margin-left: auto;
    padding: 1rem;
}


@media only screen and (max-width: 600px) {

    .hero-image .star-1,
    .hero-image .star-2,
    .hero-image .star-3,
    .hero-image .star-4,
    .hero-image .star-5 {
        display: none;
    }

}


/*product cards */

.product-card-img {
    max-height: 500px;
    max-width: 500px;
    overflow-x: hidden;
}

.product-card-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 2rem;
    flex-wrap: wrap;
}



.product-card {
    display: flex;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    border: solid 2px #150764
}

.btn-call-to-action-container {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: space-around;
    align-items: center;

    gap: 1px;
}

.product-card-txt {
    font-size: 1.5rem;
    color: #150764;
    border-bottom: solid 2px #150764;
}

.call-to-btn {
    color: white;
    width: 100%;
    padding: 1rem 0;
    background-color: #150764;

    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
}

.call-to-btn:hover {
    color: #C3D833
}

.call-to-btn:focus {
    color: #C3D833
}


.faq-container {
    min-height: 100vh;
    overflow: hidden;
    padding: 2rem;
    font-family: Inter, system-ui;
    color: rgb(36, 62, 99);
  }
  
  .faq-title {
    text-align: center;
    font-size: 2.25rem;
    font-weight: 900;
    letter-spacing: 0.025em;
  }
  
  .faq-description {
    margin-top: 1rem;
    max-width: 36rem;
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.625;
    color: rgb(124, 139, 161);
    margin: 0 auto;
    text-align: center;
  }
  
  .faq-list {
    margin-top: 4rem;
  }
  
  .faq-item {
    margin-bottom: 1.5rem;
  }
  
  .faq-question {
    font-weight: 700;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
  }
  
  .faq-toggle-icon {
    margin-left: 1rem;
  }
  
  .faq-answer {
    margin-top: 0.5rem;
    display: none;
    font-size: 0.875rem;
    color: rgb(124, 139, 161);
  }
  
  .faq-question:hover + .faq-answer {
    display: block;
  }
  
